import { useEffect, useRef } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import TWEEN from 'tween'

const SidebarScene = ({ objectDetailsObject }) => {
    
    const ref = useRef()


    return (
        <Canvas  shadows gl={{ antialias: true }} camera={{ position: [0, 500, 0.0], near: 0.5, far: 10000, fov: 35 }}  >
            <OrbitControls ref={ref} enableZoom={true} enablePan={false} />
            <SidebarObject objectDetailsObject={objectDetailsObject} controls={ref} />
        </Canvas>
    );
};

export default SidebarScene;

const SidebarObject=({objectDetailsObject,controls})=>{
    const { camera, scene } = useThree();
    const objectRef = useRef();

    // Define a new material
  const material = new THREE.MeshStandardMaterial({
    color: '#fff',
    side: THREE.DoubleSide
});
  
    useEffect(() => {
      // Clear the scene
      while (scene.children.length > 0) {
        scene.remove(scene.children[0]);
      }

      // Add lights to the scene
        const directionalLight1 = new THREE.DirectionalLight(0xffffcc, 1.2);
        directionalLight1.position.set(-800, 0,-2000);
        directionalLight1.castShadow = true;
        scene.add(directionalLight1);

        // Add lights to the scene
        const directionalLight = new THREE.DirectionalLight(0xffccff, 1.2);
        directionalLight.position.set(800, 0,2000);
        directionalLight.castShadow = true;
        scene.add(directionalLight);

       

        const ambientLight = new THREE.AmbientLight(0xffffff, 1.1);
        scene.add(ambientLight);

      if(!objectDetailsObject) return
  
      // Clone the object
      //objectDetailsObject.material =  objectDetailsObject.userData['original_material']
      let clonedObject = objectDetailsObject.clone();
      scene.add(clonedObject);
      clonedObject.material = objectDetailsObject.userData['original_material']?objectDetailsObject.userData['original_material']:material
      objectRef.current = clonedObject;

      
  
      // Adjust camera to fit the object
      const box = new THREE.Box3().setFromObject(clonedObject);
      const size = box.getSize(new THREE.Vector3());
      const center = box.getCenter(new THREE.Vector3());

      console.log(center)
  
      new TWEEN.Tween(controls.current.target)
        .to(
            {
                x: center.x,
                y: center.y,
                z: center.z,
            },
            1100
        )
        .easing(TWEEN.Easing.Cubic.Out)
        .start()

    new TWEEN.Tween(camera.position)
        .to(
            {
                x: center.x,
                y: center.y,
                z: center.z-100,
            },
            1000
        )
        .easing(TWEEN.Easing.Cubic.Out)
        .start()

    console.log(objectDetailsObject.userData['original_material'])
    }, [objectDetailsObject, camera, scene]);

    return(null)
}
