import React, { useEffect, useState } from 'react'
import StopeChart from './StopeChart';

export default function StopeStatistics() {  
  

  return (
    <div className={"flex flex-col absolute inset-0 my-auto overflow-y-auto h-full   bg-gray-100 p-4"}>
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">{'PK UNDERGROUND STOPE SATISTICS'}</h2>
      </div>
      
      <StopeChart/> 
    </div>
  )
}







