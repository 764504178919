export function calculateDistancesAndAngles(points) {
  if (points.length < 3) return null;

  // [[point1,point2,dx,dy,dz,distance,dip,bearing,angle],...]
  const measurements = []

  
  for( let i=0;i<points.length-1;i++){
    console.log(i)
    const p1 = points[i];
    const p2 = points[i+1];
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    const dz = p2.z - p1.z;
  
    const distance = Math.sqrt(dx * dx + dy * dy + dz * dz);
    const angleXY = Math.atan2(dy, dx) * (180 / Math.PI);
    const angleXZ = Math.atan2(dz, dx) * (180 / Math.PI);
    
    const measurement_set = {
      p1:p1,
      p2:p2,
      dx:dx, 
      dy:dy, 
      dz:dz, 
      dist:distance, 
      d:angleXY, 
      a:angleXZ,
      arc:0
    }
    measurements.push(measurement_set)
  }
  
  return measurements;
}

export function distanceMeasure(points) {
  if (points.length != 2) return null;

  const p1 = points[0];
  const p2 = points[1];
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  const dz = p2.z - p1.z;

  const distance = Math.sqrt(dx * dx + dy * dy + dz * dz);
  const angleXY = Math.atan2(dy, dx) * (180 / Math.PI);
  const angleXZ = Math.atan2(dz, dx) * (180 / Math.PI);
  
  const measurement_set = {
    p1:p1,
    p2:p2,
    dx:Number(dx).toFixed(1), 
    dy:Number(dy).toFixed(1), 
    dz:Number(dz).toFixed(1), 
    dist:Number(distance).toFixed(1), 
    d:Number(angleXY).toFixed(1), 
    a:Number(angleXZ).toFixed(1),
    arc:0
  }

  return measurement_set;
}

