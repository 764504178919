import React, { useEffect, useState } from 'react'
import { useClickStore } from '../store/clickStore';
import { useShallow } from 'zustand/react/shallow';
import { HexColorPicker } from 'react-colorful';

export default function ColorChange() {

    const [color, setColor] = useState('#ff08ff');

    
    const {colorMaterial,clickType} = useClickStore(useShallow((state)=>({
        colorMaterial:state.colorMaterial,
        clickType:state.clickType
    })))

     useEffect(()=>{
        if(colorMaterial){
            const hexColor = colorMaterial.color.getHexString();
            setColor(hexColor)
        }
    },[colorMaterial])

    useEffect(()=>{
        if(colorMaterial){
            colorMaterial.color.set(color);
        }
    },[color])

  return (
    <div className='colorPicker'>
        {clickType=='color' && colorMaterial &&<HexColorPicker color={color} onChange={setColor} />}
    </div>
  )
}
