import React, { useEffect } from 'react'
import {distanceMeasure} from '../components/Utils/distanceCalculator.js'
import { useClickStore, useInforStore } from '../store/clickStore.js'
import { useShallow } from 'zustand/react/shallow'
import { Line, Sphere } from '@react-three/drei'

export default function MeasureLines() {
    const {clickType,measure_points} = useClickStore(useShallow((state)=>({
        clickType:state.clickType,
        measure_points:state.measure_points
    })))

    const {setInfo} =    useInforStore(useShallow((state)=>({
        setInfo:state.setInfo
    })))

   
    useEffect(()=>{
        const measure_results = distanceMeasure(measure_points)
        if(measure_results){
            let info = `Direction:${measure_results.a}(deg) Dip:${measure_results.d}(deg) Elevation:${measure_results.dy}m Distance:${measure_results.dist}m `
            setInfo(info)
        }     
    }
    ,[measure_points])

    return (
        <group>
            {clickType=='distance' && measure_points.length==2 && <Line
                points={measure_points}       // Array of points, Array<Vector3 | Vector2 | [number, number, number] | [number, number] | number>
                color="hotpink"                   // Default
                lineWidth={2}                   // In pixels (default)
                segments                        // If true, renders a THREE.LineSegments2. Otherwise, renders a THREE.Line2
                dashed={false}                  // Default
            />}
            {clickType=='distance' && measure_points[0] && <Sphere position={measure_points[0]} ><meshBasicMaterial color='hotpink'/></Sphere>}
            {clickType=='distance' && measure_points[1] && <Sphere position={measure_points[1]}><meshBasicMaterial color='hotpink'/></Sphere>}
        </group>
    )
}
