import { useControls } from "leva"
import { ModelListPK } from "./Utils/ModelsList.js"
import { Suspense, useEffect, useState } from "react";
import ModelsSetup from "./pickstone/ModelsSetup.js";
import BlockModels from "./BlockModels.js";
import HolesLoader from "./Utils/HolesLoader.js"
import { useFrame, useThree } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import { Vector3 } from "three";
import { useClickStore } from "../store/clickStore.js";


export default function PickstoneModels({controls}) {
    const name_ = 'BRECKRIDGE'
    const {visible} = useControls(name_, {visible:false});
    
    return (
        <>
        
        { visible && 
            <group>
                <Levels controls={controls}/>
                <HolesLoader/>
                <BlockModels url='./files/cp_blockmodel.zip' name='CP' dx={-2.4} dy={0} dz={-300} size='17mb' grade='Au_gpt'  controls_={controls} />   
                <BlockModels url='./files/tm_blockmodel.zip' name='TM' dx={0} dy={0} dz={0} size='9mb' grade='AU'  controls_={controls} />   
                {ModelListPK.map((model) => (
                    <Suspense fallback={null} key={model.name}>
                        <ModelsSetup controls_={controls} group_={name_+'.'+model.group} name_={model.name} models={model.models} />
                    </Suspense>
                ))}
            </group>
            
        }
        </>
    )
}

function Levels({ controls }) {
    // const { camera } = useThree()
    // const [isVisible, setIsVisible] = useState(true);

    
    const {showLabels} = useClickStore()

    var levels = [
        ["2L",1120],
        ["3L",1086],
        ["4L",1055],
        ["5L",1013],
        ["6L",934],
        ["7L",888],
        ["8L",847],
        ["9L",801],
        ["10L",754],
        ["11L",667],
        ["12L",595],
        ["13L",522],
        ["14L",445],
        ["15L",375],
        ["16L",305],
        ["17L",235],
        ["18L",165],
        ["19L",95],
        ["20L",25],
        ["SEA LEVEL",0],
        ["21L",-45],
        ["22L",-115],
        ["23L",-185],
        ["24L",-255]
    ]

    // useFrame(({ camera }) => {
    //     const distance = camera.position.distanceTo(new Vector3(205650-205200, 800-1180, 7975000-7975010));
    //     setIsVisible(distance <= 5000);
    //     //console.log('distance checked',distance,distance <= 2000)
    //   });

    return (
        <>
           {showLabels && levels.map((a, i) => {
                return (
                    <Html 
                        key={i} 
                        position={[205650-205200, a[1]-1180, 7975000-7975010]}
                        occlude="blending"
                        center
                        transform
                        sprite
                        distanceFactor={500}
                        >
                        <div className="levels" onClick={() => console.log('.')}>
                            {a[0] + '-' + (1180-a[1])+'m (' + a[1]+'RL)'} 
                        </div>
                        
                    </Html>
                )
            })
            }
        </>
    )
}
