import React, { useEffect, useRef } from 'react'
import { useClickStore } from '../store/clickStore';
import { useShallow } from 'zustand/react/shallow';
import html2canvas from 'html2canvas';

export default function Measurements() {

    const ref = useRef()

    const {clickType,setClickType,setZoomToExtent,setLabels,showLabels} = useClickStore(useShallow((state)=>({
        clickType:state.clickType,
        setClickType:state.setClickType,
        setZoomToExtent:state.setZoomToExtent,
        setLabels:state.setLabels,
        showLabels:state.showLabels
    })))


    useEffect(() => {
        
        console.log('click type changed',clickType)
        // Define cursor styles for each click type
        const cursorStyles = {
            pan: 'pointer',
            info: 'help',
            distance: 'crosshair',
            zoom: 'zoom-in',
            color: 'move'
        };

        // Update the cursor style based on the current click type
        document.body.style.cursor = cursorStyles[clickType] || 'default';

        // Clean up on unmount
        return () => {
            document.body.style.cursor = 'default';
        };
    }, [clickType]);

    const handleButtonClick = (id) => {
        setClickType(id);
    }
    

  return (
    <div id='actions' ref={ref}>
        <button 
            onClick={() => setZoomToExtent(true)}
        >E</button>
        <button 
            onClick={() => handleButtonClick('pan')}
            className={clickType === 'pan' ? 'active' : ''}
        >P</button>
        <button 
            onClick={() => handleButtonClick('info')}
            className={clickType === 'info' ? 'active' : ''}
        >I</button>
        <button 
            onClick={() => handleButtonClick('distance')}
            className={clickType === 'distance' ? 'active' : ''}
        >D</button>
        <button 
            onClick={() => handleButtonClick('zoom')}
            className={clickType === 'zoom' ? 'active' : ''}
        >Z</button>
        <button 
            onClick={() => handleButtonClick('color')}
            className={clickType === 'color' ? 'active' : ''}
        >C</button>
        <button className= {showLabels?'shown':''}
            onClick={() => setLabels()}
        >L</button>
        
    </div>
  )
}

