import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';
import * as THREE from 'three';
import { useControls  } from 'leva';
import dataCache from './Utils/dataCache.js'; 
import JSZip from 'jszip';
import { Html } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function BlockModels({ name, url , dx ,dy ,dz , size  , grade ,controls_}) {
  const name_ = 'BRECKRIDGE.Pickstone.BlockModels.' + name;
  const [{ bmvisible , interval},set] = useControls(name_, ()=>({ bmvisible: false , interval:{ min: 0,
    max: 20,value: [1, 2],step:0.05}}));

  const instancedMeshRef = useRef();
  const [ogdata, setOGData] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const temp = new THREE.Object3D();
  const color = new THREE.Color();
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [tooltip, setTooltip] = useState({ show: false, content: '', x: 0, y: 0 , z:0 });

  const fetchDataWithProgress = async (url) => {
    const response = await fetch(url);
    const reader = response.body.getReader();
    const contentLength = +response.headers.get('Content-Length');

    let receivedLength = 0;
    const chunks = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      chunks.push(value);
      receivedLength += value.length;

      console.log(`Received ${((receivedLength / contentLength) * 100).toFixed(2)}% of ${contentLength} bytes`);
    }

    return new Blob(chunks);
  };

  // Load data when component mounts
  useEffect(() => {
    if(!bmvisible) return
    console.log('model search');
      const fetchData = async () => {
        if (dataCache[url]) {
          console.log('local data found');
          setOGData(dataCache[url])
        }else{
          const userConfirmed = window.confirm(`Block model is not available on your cache , do you want to download it @ ${size}?`);
          if (userConfirmed) {
            console.log('downloading data')

            //////////////
            try {
              const blob = await fetchDataWithProgress(url);
              console.log('Download complete. Extracting...');
              const zip = await JSZip.loadAsync(blob);
              const fileName = Object.keys(zip.files)[0]; // Assuming there's only one file in the zip
              const fileData = await zip.file(fileName).async('string');
              const parsedData = d3.csvParse(fileData);
          
              setOGData(parsedData);
              dataCache[url] = parsedData; 
              
            } catch (error) {
              console.error('Error loading the zipped CSV file:', error);
            }
            
          }else{
            set({ bmvisible: false  });
          }
        
        }
      }

    fetchData()
    
  }, [url,bmvisible]); // Only load data when URL changes

  useEffect(()=>{
    
    let tempArr = [];
    for (let i = ogdata.length - 1; i >= 0; i--) {
      // get the current object
      // check if the object's grade is within the range
      if (ogdata[i][grade] >= interval[0] && ogdata[i][grade]  <= interval[1]) {
        tempArr.push(ogdata[i])
      }
    }
    setData(tempArr)
    setCount(tempArr.length); 
  },[ogdata,interval])

  // Memoize colors calculation
  const colors = useMemo(() => {
    if (data.length === 0) return new Float32Array();
    const colorsArray = new Float32Array(data.length * 3);
    for (let i = 0; i < data.length; i++) {
      const value = parseFloat(data[i][grade]);
      if (value >= 3) {
        color.set(0xac02b4);
      } else if (value >= 1.5) {
        color.set(0xff0000);
      } else if (value >= 0.6) {
        color.set(0x00ff00);
      } else {
        color.set(0x0000ff);
      }
      colorsArray[i * 3] = color.r;
      colorsArray[i * 3 + 1] = color.g;
      colorsArray[i * 3 + 2] = color.b;
    }
    return colorsArray;
  }, [data,bmvisible]);

  useEffect(() => {
    console.log('visibility toggled')
    if (data.length > 0 && instancedMeshRef.current) {
      for (let i = 0; i < data.length; i++) {
        const x = data[i].XC - 205200 + dx;
        const y = data[i].ZC - 1180 +dy;
        const z = 7975000 - data[i].YC +dz;
        temp.position.set(x, y, z);
        temp.updateMatrix();

        instancedMeshRef.current.setMatrixAt(i, temp.matrix);
      }

      // Update the instance
      instancedMeshRef.current.instanceMatrix.needsUpdate = true;
    }
  }, [data,bmvisible]);
  const { camera } = useThree()
  const handleInstanceClick = (event, instanceIndex) => {
    event.stopPropagation();
    const instanceData = data[instanceIndex];
    const x = instanceData.XC - 205200 + dx;
    const y = instanceData.ZC - 1180 + dy;
    const z = 7975000 - instanceData.YC +dz;
    const Au_gpt  = data[event.instanceId][grade];
    
    setSelectedInstance({ x, y, z });
    setTooltip({ show: true, content: `${name}: ${Number(Au_gpt).toFixed(2)}`, x: x-2, y: y+1.5 , z:z });
   
  };

  const handleTooltipClick = (event) => {
    event.stopPropagation()
    setTooltip({ show: false, content: '', x: 0, y: 0 , z:0 });
    setSelectedInstance(null)
  };


  return bmvisible ? (
    <group>
    <instancedMesh ref={instancedMeshRef} args={[null, null, count]} 
    onClick={
      (event)=>{
        const instanceId = event.instanceId;
        if (instanceId !== undefined) handleInstanceClick(event, instanceId);
      }
    }>
      <boxGeometry args={[0.9, 0.9, 0.9]}>
        <instancedBufferAttribute attach="attributes-color" args={[colors, 3]} />
      </boxGeometry>
      <meshLambertMaterial vertexColors toneMapped={false} />
    </instancedMesh>
    {tooltip.show && (
      <Html position={[tooltip.x, tooltip.y, tooltip.z]}>
      <div  className='annotationBody' 
        onClick={(event)=>handleTooltipClick(event)}
      >
        <div
          className="annotationDescription">
        {tooltip.content}
        </div>
      </div>
      </Html>
    )}
    {selectedInstance && (
      <mesh position={[selectedInstance.x, selectedInstance.y, selectedInstance.z]}>
        <boxGeometry args={[1, 1, 1]} />
        <meshBasicMaterial color="blue" transparent opacity={0.5} />
      </mesh>
    )}
  
  </group>

  ) : null;

}
