import React, { useEffect, useState } from 'react'
import { useClickStore } from '../store/clickStore'
import { useShallow } from 'zustand/react/shallow'
import * as THREE from 'three'

export default function ObjectDetails() {

    const {objectDetails,objectDetailsPosition,objectDetailsObject,clickType} = useClickStore(useShallow((state)=>({
        objectDetails:state.objectDetails,
        objectDetailsPosition:state.objectDetailsPosition,
        objectDetailsObject:state.objectDetailsObject,
        clickType:state.clickType,
    })))

    const [previousObject, setPreviousObject] = useState(null);
    const [originalMaterial, setOriginalMaterial] = useState(null);
    const [originalMaterialColor,setOriginalMaterialColor] = useState("#000");

    const yellowMaterial = new THREE.MeshBasicMaterial({ color: "#ffff00" });
    const yellowLineMaterial = new THREE.LineBasicMaterial({ color: "#ffff00" });

    useEffect(() => {
        if (objectDetailsObject &&objectDetailsObject.name!=='bm') {
            // Revert the material of the previous object
                    console.log(previousObject,originalMaterialColor)
            if (previousObject && previousObject !== objectDetailsObject) {
                if(previousObject.material.isLineMaterial){
                    // console.log(previousObject,originalMaterial)
                    // previousObject.material.color.set(originalMaterialColor)
                }else{
                    previousObject.material = originalMaterial;
                }
            }

            if(objectDetailsObject.isInstancedMesh){return}

            // Update the previous object state
            setPreviousObject(objectDetailsObject);
            console.log(objectDetailsObject.material.isLineMaterial)

            
            setOriginalMaterial(objectDetailsObject.material);
            ///objectDetailsObject.userData['original_material'] = objectDetailsObject.material;

            if(objectDetailsObject.material && !(objectDetailsObject.material.isLineMaterial)){
                // Store the original material of the current object
                if (objectDetailsObject.material !== yellowMaterial) {
                    // Assign the yellow material to the current object
                    objectDetailsObject.material = yellowMaterial;
                }
            }else if((objectDetailsObject.material.isLineMaterial)){
                // setOriginalMaterialColor(objectDetailsObject.material.color);
                // objectDetailsObject.material.color.set('#ffff00')
            }


            console.log(originalMaterial)
        }
            
        
    }, [objectDetailsObject]);

    useEffect(()=>{
        if(clickType!=='info'){
            if (previousObject) {
                previousObject.material = originalMaterial;
            }
        }
    },[clickType])

    const rows = objectDetails? Object.entries(objectDetails).map(([key, value]) => (
        <tr key={key}>
            <td>{key}</td>
            <td>{value}</td>
        </tr>
    )):<></>;

    useEffect(()=>{
        console.log('Object Details rerendered')
    },[])

  return (
    // <group>
    //     {clickType =='info' && objectDetailsObject&&objectDetailsObject.name!=='bm'&&objectDetails&&
    //         <Html position={objectDetailsPosition}>
    //             <div className='objectDetails'>
    //             <text className='detailsHeader'>{objectDetails.Name}</text>
    //             <table  className='detailsTable'>
    //                 <thead>
    //                     <tr>
    //                     <th>Name</th>
    //                     <th>Value</th>
    //                     </tr>
    //                 </thead>
    //                 <tbody  >
    //                     {rows}
    //                 </tbody>
    //             </table>
    //             </div>
    //         </Html>
    //     }
    // </group>
    null
  )
}
