import React, { useEffect, useState } from 'react';
import {XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Bar, ComposedChart, Area } from 'recharts';
import JSZip from 'jszip';
import * as d3 from 'd3';
import MultiSelectDropdown from '../multiselect/MultiSelectDropdown';

const colors = ["#00ff00", "#ff0000", "#ff7300", "#ff0000", "#00ff00", "#0000ff"]; 
const StopeChart = ({stopeID}) => {
  const [data, setData] = useState([]);
  const [alldata, setAllData] = useState({});

  
  const [stopeData, setStopeData] = useState([[],'Month',[]]);

  const [summary_field, setSummaryField] = useState('Month');

  const [parsedData, setParsedData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('./files/ug_ore_source_reconciliation.zip');
      const blob = await response.blob();
      const zip = await JSZip.loadAsync(blob);
      const file = zip.file('ug_ore_source_reconciliation.csv');
      const csvData = await file.async('string');
      
      const parsedCsvData = d3.csvParse(csvData);
      setParsedData(parsedCsvData);
    };
  
    fetchData();
  }, []);
  
  

  // stopeID = null
  useEffect(() => {     
      if(!parsedData) return

      console.log(parsedData)
      let filteredData = parsedData;
      if (stopeID) {
        filteredData = parsedData.filter(row => row['Ore source'] === stopeID);
      }

      if(stopeData[0].length>0){
        filteredData = filteredData.filter(row => stopeData[0].includes(row['Level']));
      }

      if(stopeData[2].length>0){
        filteredData = filteredData.filter(row => stopeData[2].includes(row[stopeData[1]]));
      }

      console.log(filteredData)

      const summary = {};
      const totalSummary = {
        Planned_Tonnage: 0,
        Mined_Tonnage: 0,
        Sampled_Grade: 0,
        Actual_Grade: 0,
        Planned_Content: 0,
        Actual_Content: 0,
        Planned_Tonnage_Sum: 0,
        Mined_Tonnage_Sum: 0
      };

      filteredData.forEach(row => {
        const key = row[summary_field];
        if (!summary[key]) {
          summary[key] = {
            Planned_Tonnage: 0,
            Mined_Tonnage: 0,
            Sampled_Grade: 0,
            Actual_Grade: 0,
            Planned_Tonnage_Sum: 0,
            Mined_Tonnage_Sum: 0
          };
        }
        
        summary[key].Planned_Tonnage += +row['Planned Tonnage (t)'];
        summary[key].Mined_Tonnage += +row['Mined Tonnage (t)'];
        if (+row['Sampled Grade (g/t)'] > 0 && +row['Planned Tonnage (t)'] > 0) {
          summary[key].Sampled_Grade += +row['Sampled Grade (g/t)'] * +row['Planned Tonnage (t)'];
          summary[key].Planned_Tonnage_Sum += +row['Planned Tonnage (t)'];
        }
        if (+row['Actual grade (g/t)'] > 0 && +row['Mined Tonnage (t)'] > 0) {
          summary[key].Actual_Grade += +row['Actual grade (g/t)'] * +row['Mined Tonnage (t)'];
          summary[key].Mined_Tonnage_Sum += +row['Mined Tonnage (t)'];
        }

        totalSummary.Planned_Tonnage += +row['Planned Tonnage (t)'];
        totalSummary.Mined_Tonnage += +row['Mined Tonnage (t)'];
        if (+row['Sampled Grade (g/t)'] > 0 && +row['Planned Tonnage (t)'] > 0) {
          totalSummary.Planned_Content += +row['Sampled Grade (g/t)'] * +row['Planned Tonnage (t)'];
          totalSummary.Planned_Tonnage_Sum += +row['Planned Tonnage (t)'];
        }
        if (+row['Actual grade (g/t)'] > 0 && +row['Mined Tonnage (t)'] > 0) {
          totalSummary.Actual_Content += +row['Actual grade (g/t)'] * +row['Mined Tonnage (t)'];
          totalSummary.Mined_Tonnage_Sum += +row['Mined Tonnage (t)'];
        }

      });

      totalSummary.Sampled_Grade = totalSummary.Planned_Content/totalSummary.Planned_Tonnage_Sum
      totalSummary.Actual_Grade = totalSummary.Actual_Content/totalSummary.Mined_Tonnage_Sum

      const summaryData = Object.keys(summary).map(key => ({
        [summary_field]: key,
        Planned_Tonnage: summary[key].Planned_Tonnage,
        Mined_Tonnage: summary[key].Mined_Tonnage,
        Sampled_Grade: summary[key].Sampled_Grade / summary[key].Planned_Tonnage_Sum,
        Actual_Grade: summary[key].Actual_Grade / summary[key].Mined_Tonnage_Sum,
        Planned_Content: summary[key].Sampled_Grade,
        Actual_Content:summary[key].Actual_Grade
      }));

      setAllData(totalSummary)
      setData(summaryData);

      console.log(summaryData)
    
  }, [stopeID, summary_field,stopeData,parsedData]);

  return (
    
    <> 
    {!stopeID && <Card parsedData={parsedData} setStopeData={setStopeData}/> }
    <select
        value={summary_field}
        onChange={(e) => setSummaryField(e.target.value)}
        className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option value="Month">Month</option>
        <option value="Quarter">Quarter</option>
        <option value="Year">Year</option>
      </select>

      <div className='grid xl:grid-cols-2 lg:grid-cols-4 w-full gap-5 max-w-[1400px] py-4'>
          <GridItem1 title="Planned Tonnage" value={Number(alldata.Planned_Tonnage).toLocaleString("en-EN", { maximumFractionDigits: 0 })}  prefix={'t'}> </GridItem1>
          <GridItem1 title="Mined Tonnage" value={Number(alldata.Mined_Tonnage).toLocaleString("en-EN", { maximumFractionDigits: 0 })}  prefix={'t'}> </GridItem1>
          <GridItem1 title="Planned Grade" value={Number(alldata.Sampled_Grade).toLocaleString("en-EN", { maximumFractionDigits: 2 })}  prefix={'g/t'}> </GridItem1>
          <GridItem1 title="Actual Grade" value={Number(alldata.Actual_Grade).toLocaleString("en-EN", { maximumFractionDigits: 2 })}  prefix={'g/t'}> </GridItem1>
          <GridItem1 title="Gold Mined" value={Number(alldata.Planned_Content).toLocaleString("en-EN", { maximumFractionDigits: 0 })}  prefix={'g'}> </GridItem1>
          <GridItem1 title="Gold Mined" value={Number(alldata.Actual_Content).toLocaleString("en-EN", { maximumFractionDigits: 0 })}  prefix={'g'}> </GridItem1>
      </div>     
      <GridItem title="Broken Tonnes Trend" key={1}>
        <SummaryChart
          stopeID={stopeID}
          summary_field={summary_field}
          data={data}
          target_fields={
            [
              { column_name: 'Planned Tonnage (t)', legend_name: 'Planned' , graphKey:'Planned_Tonnage' },
              { column_name: 'Mined Tonnage (t)', legend_name: 'Mined', graphKey:'Mined_Tonnage'  }
            ]
          }
          graphOptions={{
            graph_type: 'BarChart', // Example graph type
            graph_title: 'Ore Source Reconciliation',
            x_title: 'Month',
            y_title: 'Tonnage (t)'
          }}
        />
      </GridItem>  

      <GridItem title="Grade Trend"  key={2}>
        <SummaryChart
          stopeID={stopeID}
          summary_field={summary_field}
          data={data}
          target_fields={
            [
              { column_name: 'Sampled Grade (g/t)', legend_name: 'Sampled' , graphKey:'Sampled_Grade' },
              { column_name: 'Actual grade (g/t)', legend_name: 'Actual', graphKey:'Actual_Grade'  }
            ]
          }
          graphOptions={{
            graph_type: 'BarChart', // Example graph type
            graph_title: 'Ore Source Reconciliation',
            x_title: 'Month',
            y_title: 'Grade (g/t)'
          }}
        />
      </GridItem> 

      <GridItem title="Content Trend" key={3}>
        <SummaryChart
          stopeID={stopeID}
          summary_field={summary_field}
          data={data}
          target_fields={
            [
              { column_name: 'Planned Tonnage (t)', legend_name: 'Planned' , graphKey:'Planned_Content' },
              { column_name: 'Mined Tonnage (t)', legend_name: 'Actual', graphKey:'Actual_Content'  }
            ]
          }
          graphOptions={{
            graph_type: 'BarChart', // Example graph type
            graph_title: 'Ore Source Reconciliation',
            x_title: 'Month',
            y_title: 'Gold (g)'
          }}
        />
      </GridItem>    
      
      </>
   
  );
};

export default StopeChart;

const SummaryChart = ({ data, summary_field, target_fields, graphOptions })=>{
  return(
    <ResponsiveContainer width="100%" height="100%">
    <ComposedChart
      width={500}
      height={500}
      data={Object.values(data)}
      margin={{
        top: 20,
        right: 10,
        bottom: 20,
      }}
    >
      <CartesianGrid strokeDasharray="5 5" />
      <XAxis dataKey={summary_field}  fontSize={10}  tick={{ fill: 'white' }} label={{ value: summary_field, position: 'insideBottomRight', offset: 0 }} />
      <YAxis  fontSize={10}  tick={{ fill: 'white' }} label={{ value: graphOptions.y_title, angle: -90, position: 'insideLeft' }} />      
      <Tooltip content={CustomTooltip} />
      <Legend fontSize={8} />
    
      {target_fields.map((field,index) => (
        <Bar key={field.graphKey} fill={colors[index]} barSize={20} dataKey={field.graphKey} name={field.legend_name}/>
      ))} 
    </ComposedChart>
    </ResponsiveContainer>
  )
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    //console.log(payload)
    return (
      <div className="p-4 bg-slate-900 flex flex-col gap-4 rounded-md">
        <p className="text-medium text-lg text-white">{` ${''+label}`}</p>
        {
          payload.map((item,index)=>{
            return(
            <p className="text-sm text-blue-400" key={index}>
              {item.name}:
            <span className="sm-2">{ Number(item.value).toLocaleString("en-EN", { maximumFractionDigits: item.name.endsWith('Grade')?2:0 })}</span>
            </p>
            )
          })
        }
        
        
      </div>
    );
  }

  return null;
};

function GridItem({title,children}){
  return(
      <div className='flex flex-col items-center justify-center  border border-slate-500 bg-stone-800 min-h-52 rounded-md shadow-lg mt-4'>
          <h3 className='text-xl font-semibold text-white mb-1'>{title}</h3>
          {children}
      </div>
  )
}

function GridItem1({title,value,prefix,children}){
  return(
      <div className='flex flex-col items-center justify-center  border h-[100px]  rounded overflow-hidden shadow-lg '>
          <h3 className='text-sm font-semibold text-green-800 mb-1'>{title}</h3>
          <h3 className='text-sm font-semibold text-orange-600 mb-1'>{value}  <span className='text-sm font-semibold text-orange-300 mb-1' >{prefix}</span></h3>
          {children}
      </div>
  )
}


const Card = ({ parsedData,setStopeData}) => {
  if(!parsedData) return
  if(!parsedData.length>0) return

  const [selectedLevels, setSelectedLevels] = useState(['1L', '2L', '3L', '4L', '5L', '6L']);
  const [period, setPeriod] = useState('Month');
  const [selectedPeriod , setSelectedPeriod] = useState([])
  const [selectPeriodOptions , setSelectPeriodOptions] = useState([])

  

  const levels = ['1L', '2L', '3L', '4L', '5L', '6L'];
  const periods = ['Month', 'Quarter', 'Year'];

  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);
  };

  useEffect(()=>{
    console.log(selectedLevels,period,selectedPeriod)
    setStopeData([selectedLevels,period,selectedPeriod])
  },[selectedLevels,period,selectedPeriod])

  useEffect(()=>{
    setSelectedPeriod([])
    console.log([...new Set(parsedData.map((item) => item[period]))])
    setSelectPeriodOptions([...new Set(parsedData.map((item) => item[period]))])
  },[period])


  const itemClickedPeriod = (item) => {
    setSelectedPeriod((prevSelected) => 
      prevSelected.includes(item) 
        ? prevSelected.filter((i) => i !== item) 
        : [...prevSelected, item]
    );
  };

  const itemClickedLevel = (item) => {
    setSelectedLevels((prevSelected) => 
      prevSelected.includes(item) 
        ? prevSelected.filter((i) => i !== item) 
        : [...prevSelected, item]
    );
  };

  return (
    <div className="p-4 bg-white shadow rounded">
      <div className='mb-4 mt-1 block w-full'>
        <MultiSelectDropdown label={'LEVEL'} list={levels} selectedlist={selectedLevels} itemClick={itemClickedLevel} />
      </div>
      <div className="mb-4  mt-1 block w-full">
        <div className='cursor-pointer border-solid p-4 border-stone-500 shadow-lg w-full border'>
          {/* <label className="block text-gray-700">Period</label> */}
          <select value={period} onChange={handlePeriodChange} className="mt-1 block w-full">
            {periods.map((prd) => (
              <option key={prd} value={prd}>
                {prd}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='mb-4 mt-1 block w-full'>
        <MultiSelectDropdown label={'Options'} list={selectPeriodOptions} selectedlist={selectedPeriod} itemClick={itemClickedPeriod} />
      </div>

    </div>
  );
};

