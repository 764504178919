import React, {useRef, useEffect, useMemo, useState } from 'react';
import JSZip from 'jszip';
import * as d3 from 'd3';
import { useControls } from 'leva';
import { Line } from '@react-three/drei';
import * as THREE from 'three'
import { Label } from 'recharts';

export default function HolesLoader() {
    const [holeData, setHoleData] = useState([]);
    const url =  './files/Holes050924.zip'
    const [controls, setControls] = useState({});
    const color = new THREE.Color();
    
    const constants_ = [205200,7975000,1180]
    const exclude_holes = ['AUG', 'PD','RAB','TLB','BH']
    const exclude_holetype = ['CH']

    useEffect(()=>{
        const fetchData = async () => {
          const blob = await fetchDataWithProgress(url);
          console.log('Download complete. Extracting...');
          const zip = await JSZip.loadAsync(blob);
          const fileName = Object.keys(zip.files)[0]; // Assuming there's only one file in the zip
          const fileData = await zip.file(fileName).async('string');
          const parsedData = d3.csvParse(fileData);
          setHoleData(parsedData)
        }    
        fetchData()    
    },[])

    const groupedData = useMemo(() => groupData(holeData), [holeData]);    

    useEffect(()=>{
        const contrls = {};
        Object.keys(groupedData).forEach(dataset => {
            contrls[dataset] = true;
            Object.keys(groupedData[dataset]).forEach(holeType => {
                exclude_holes.includes(holeType) ? null :contrls[`${dataset}-${holeType}`] = false;
            });
        });
        setControls(contrls);
    },[groupedData])

   
    useEffect(()=>{console.log('controls changed')},[controls])

    const { ...visibleControls } = useControls('Holes',() => {        
        // const controlsConfig = {}
        // Object.keys(controls).forEach(key => {
        //     controlsConfig[key] = {
        //         value: controls[key],
        //         onChange: (value) => setControls(prev => ({ ...prev, [key]: value }))
        //     };
        // });
        // return controlsConfig;

        const controlsConfig = {}
        Object.keys(controls).forEach(key => {
            let [dataset, holetype] = key.split('-');
            console.log(dataset, holetype)
            const folderName = holetype ? `${dataset}.${holetype}` : `${dataset}.visible`;
            holetype = holetype ? holetype:`visible`;
    
            if (!controlsConfig[folderName]) controlsConfig[folderName] = {};
            controlsConfig[folderName]= {
                value: controls[key],
                label:holetype,
                onChange: (value) => setControls(prev => ({ ...prev, [key]: value }))
            };
        });
        return controlsConfig;


    }, [controls]);

    const instances = useMemo(() => {
        if(!groupedData) return {}
        const inst = {};
        Object.keys(groupedData).map(dataset => {
            Object.keys(groupedData[dataset]).map(holeType => {
                const instanceKey = `${dataset}_${holeType}`;         
                const colorsArray = [];
                const posArray = [];
                const detArray = [];

                Object.keys(groupedData[dataset][holeType]).map(holeID=>{
                    const holeData = groupedData[dataset][holeType][holeID];
                    holeData.map(({ X, Y, Z,Depth_From, Depth_To,Au_ppm }) => {
                    if (Au_ppm > 0.5) {
                        
                        if (Au_ppm >= 5) {
                            color.set(0x800080) // PURPLE
                          } else if (Au_ppm >= 2.5) {
                            color.set(0xb45f06) // BROWN
                          } else if (Au_ppm >= 1.5) {
                            color.set(0xcc0000) // RED
                          } else if (Au_ppm >= 0.75) {
                            color.set(0x38761d) // GREEN
                          } else{
                            color.set(0xf1c232) // YELLOW
                          }
                          colorsArray.push([color.r,color.g,color.b])
                          posArray.push([Number(X)-constants_[0], Number(Z)-constants_[2], constants_[1]-Number(Y) ]);
                          detArray.push({holeID:holeID,from:Depth_From, to:Depth_To,grade:Au_ppm})
                    }
                     });
                })
                inst[instanceKey] = {colors:colorsArray,positions:posArray,details:detArray};
                
            });
        });
        console.log(inst)
        return inst;
    }, [groupedData]);

   return (
    <>
        {Object.keys(groupedData).map(dataset => (
            controls[dataset] && (
                <group key={dataset}>
                    {Object.keys(groupedData[dataset]).map(holeType => (
                         exclude_holes.includes(holeType) ? null : controls[`${dataset}-${holeType}`] && (
                            <group key={`${dataset}_${holeType}`}>
                                {exclude_holetype.includes(holeType) ? null : (
                                    <group key={`${dataset}_${holeType}_Holes`}>
                                        {/* Rendering individual lines */}
                                        {Object.keys(groupedData[dataset][holeType]).map(holeID => {
                                            const holeData = groupedData[dataset][holeType][holeID];
                                            const points = [
                                                [Number(holeData[0].NAT_East)-constants_[0], Number(holeData[0].NAT_RL)-constants_[2],constants_[1]-Number(holeData[0].NAT_North) ],
                                                ...holeData.map(({ X, Y, Z }) => [Number(X)-constants_[0], Number(Z)-constants_[2], constants_[1]-Number(Y) ])
                                            ];

                                            return (
                                                <Line
                                                    key={holeID}
                                                    points={points}
                                                    closed={false}
                                                    curveType="centripetal"
                                                    userData={{ displayData: holeData[0] }}
                                                    lineWidth={holeData[0].Hole_Status==='DRILLED'?3:holeData[0].Hole_Status==='PLANNED'?2:1}
                                                    color={holeData[0].Hole_Status==='DRILLED'?'green':holeData[0].Hole_Status==='PLANNED'?'white':'black'}
                                                    name={holeID}
                                                />
                                            );
                                        })}                        
                                        
                                    </group>
                                )}
                        
                                <group key={`${dataset}_${holeType}_Assays`}>
                                    {
                                        (instances[`${dataset}_${holeType}`])&&(
                                            instances[`${dataset}_${holeType}`].positions.length>0 && <Assays count={instances[`${dataset}_${holeType}`].positions.length}  holes={instances[`${dataset}_${holeType}`]} id={`${dataset}_${holeType}_Assay`}/>
                                        )
                                    }
                                </group>
                            </group>

                        )
                    ))}
                </group>
                )   
            ))      
        }
        
    </>
  )
}

const fetchDataWithProgress = async (url) => {
    const response = await fetch(url);
    const reader = response.body.getReader();
    const contentLength = +response.headers.get('Content-Length');

    let receivedLength = 0;
    const chunks = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      chunks.push(value);
      receivedLength += value.length;

      console.log(`Received ${((receivedLength / contentLength) * 100).toFixed(2)}% of ${contentLength} bytes`);
    }

    return new Blob(chunks);
  };

  const groupData = (data) => {
    console.log(data)
    const groupedData = {};

    data.forEach(row => {
        const { DataSet, Hole_Type, Hole_ID } = row;

        if (!groupedData[DataSet]) groupedData[DataSet] = {};
        if (!groupedData[DataSet][Hole_Type]) groupedData[DataSet][Hole_Type] = {};
        if (!groupedData[DataSet][Hole_Type][Hole_ID]) groupedData[DataSet][Hole_Type][Hole_ID] = [];

        

        groupedData[DataSet][Hole_Type][Hole_ID].push(row);
    });
    console.log(groupedData)
    return groupedData;
};


function Assays({ count,holes,id }) {
    const ref = useRef();
    const [colors, setColors] = useState(new Float32Array());
    const [instancesData, setInstancesData] = useState([]);
  
    useEffect(() => {
        let colorsArray = [];
        let idata = [];
        for(let i = 0 ; i<count;i++){
            const assayPosition = holes['positions'][i];
            const color = holes['colors'][i];
            const data = holes['details'][i];

            idata.push(data)
            const assayMatrix = new THREE.Matrix4()
                .makeTranslation(assayPosition[0], assayPosition[1], assayPosition[2])
            ref.current.setMatrixAt(i, assayMatrix);
            colorsArray.push(color[0], color[1], color[2]);
        }
        setInstancesData(idata)
        setColors(Float32Array.from(colorsArray));
        ref.current.instanceMatrix.needsUpdate = true;
    }, [holes]);

    return (
      <instancedMesh ref={ref} args={[null, null, count]} userData={{instancesData:instancesData}} name={id}>
        <sphereGeometry args={[0.5, 4, 4]} >
          <instancedBufferAttribute attach="attributes-color" args={[colors, 3]} />
        </sphereGeometry>
        <meshLambertMaterial vertexColors toneMapped={false} />
      </instancedMesh>
     
    );
  }