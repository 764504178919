import { useAnimations, useGLTF } from '@react-three/drei'
import React, { useEffect } from 'react'

export default function Skip() {
    
    const skip = useGLTF('./models/Animations/Skip.glb')
    const animations = useAnimations(skip.animations,skip.scene)

    useEffect(()=>{
        console.log(animations);
        const move = animations.actions['SkipAction.003']
        move.play()
    },[])

  return (
    <primitive object={skip.scene}/>
  )
}

