import { useAnimations, useGLTF } from '@react-three/drei'
import React, { useEffect } from 'react'
import {useControls} from 'leva'

export default function Cart() {
    
    const cart = useGLTF('./models/Animations/Cart.glb')
    const animations = useAnimations(cart.animations,cart.scene)
    const {visible,animationName} = useControls('Cart',{        
            visible:false,
            animationName:{options:['Stop','Drive']}
        }
    )    

    useEffect(()=>{
        const move = animations.actions['Action']
        if(animationName=='Stop'){
            move.stop()
        }else{
            move.play()
        }     
    },[animationName])

  return (
    <primitive object={cart.scene} visible={visible}/>
  )
}

