import React from 'react'
import { useSidebarStore } from "../../store/sidebarStore";
import StopeStatistics from './StopeStatistics';
import ObjectDetailsPage from './ObjectDetailsPage';

export default function LeftBar() {
    const { showStopeStatistics } = useSidebarStore();

  return (
     !showStopeStatistics ? <StopeStatistics /> : <ObjectDetailsPage />
  )
}
