import { create } from "zustand";

export const useClickStore =  create((set)=>({
    clickType:'pan',
    setClickType:(clickType_)=>{set((state)=>({
        clickType:clickType_,
        instanceId:null
    }))},
    measure_points:[],
    setClickParams:(point,object,instanceId)=>{
        set((state)=>({
            objectDetails:state.clickType === 'info' 
            ? (instanceId 
                ? (object.userData.instancesData ? object.userData.instancesData[instanceId] : null) 
                : (object.userData.displayData ? object.userData.displayData : null)
              ) 
            : null,
            objectDetailsPosition:(state.clickType==='info' && (object.userData.displayData||object.userData.instancesData))?point:null,
            objectDetailsObject:(state.clickType==='info' && (object.userData.displayData||object.userData.instancesData))?object:null,
            measure_points:(state.clickType==='distance' && state.measure_points.length<2)?[...state.measure_points,point]:(state.clickType==='distance'? [point]:[]),
            zoomPosition:(state.clickType==='zoom')?point:null,
            clickType:(state.clickType==='zoom')?'pan':state.clickType,
            colorMaterial:(state.clickType==='color')?object.material:null,
            currentObjectName:object.name,
            instanceId:state.clickType === 'info'?(instanceId?instanceId:null):null
        }))
    },
    instanceId:null,
    objectDetails:'',
    objectDetailsPosition:null,
    objectDetailsObject:null,
    zoomPosition:null,
    colorMaterial:null,
    currentObjectName:null,
    zoomToExtent:false,
    setZoomToExtent:(z)=>set({zoomToExtent:z}),
    showLabels:false,
    setLabels:()=>set((state)=>({showLabels:!state.showLabels}))

}));

export const useInforStore = create((set)=>({
    info:'...',
    setInfo:(info_)=>set({info:info_})
}));

